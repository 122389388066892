import { useMemo, useState } from 'react'
import Preloader from '../../UI/Preloader'
import FrontTaskCard from './FrontTaskCard'
import Modal, { ModalContainer } from '@common/Modal'
import ChooseSeminarian from './Modals/ChooseSeminarian'
import s from './FrontTaskCardList.module.css'


export const FrontTaskCardList = ({ works, refD, loading, getLinkAllDz=false, ...props }) => {
  const initStateModal = useMemo(()=>{
    return {
      type:'',
      data: {}
    }
  }, [])
  const [modal, setModal] = useState(initStateModal) 

  const openModal = (type, data) => {
    setModal({
      type,
      data
    })
  }

  const closeModal = () => setModal(initStateModal);

  const getLink = data => {
    
    let type = getLinkAllDz ? data.type : props.type;

    if (props.role !== 'children') {
      switch (type) {
        case 'probe': return `/dz-students/checkingProbe/${data.id}`
        case 'control': return `/dz-students/checkingControl/${data.id}`
        default: return `/dz-students/checking/${data.id}`
      }
    }

    switch (type) {
      case 'probe': return `/home-work/probe-${data.work_id}`
      case 'control': return `/home-work/control-${data.id}`
      default: return false
    }
  }

  const params = {}
  if (refD) params.ref = refD

  return <>
    <div {...params} className={s.list}>
      {works?.map(card => <FrontTaskCard key={card.id} data={card} link={getLink(card)} openModal={openModal} {...props} />)}
      {loading && <Preloader fullScreen='center' />}
    </div>
    <Modal className={s.modal} active={modal.type} setActive={closeModal}>
      {
        modal.type == 'chooseSeminarian' && <ModalContainer closeModal={closeModal}>
          <ChooseSeminarian
            setActive={closeModal}
            data={ modal.data }
          />
        </ModalContainer>
      }
    </Modal>
  </>
}
