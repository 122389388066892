import classNames from 'classnames'
import s from './ChoosingOption.module.css'
import { AnswerButton } from '../AnswerButton/AnswerButton'
import JsonPars from '../../../UI/JsonPars/JsonPars'
import { jsonConvert } from '../../../../helpers/jsonConvert'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export const ChoosingOption = ({ type,   ...props }) => {
  const correct = jsonConvert(props.correct_answer, 'json')
  if(type == 'decided'){
    return <Decided {...props} />
  }
  if (type == 'correction') {
    return <Correction {...props} />
  }
  if(Number.isInteger(correct) || correct?.length == 1) { 
    return <Task hist={props?.hist}   { ...props} />
  } 
  else {
    return <TaskDZ hist={props?.hist}   { ...props} />
  }
 
}

const Correction = ({ variants }) => {
  return (
    <div className={s.multiChoiceTestContainer}>
      {variants?.map(variant => (
        <button key={variant.index} className={s.btn} disabled={true}>
          <JsonPars json={variant.text} />
        </button>
      ))}
    </div>
  )
}

const Decided = ({ userRole, ...props }) => {
 
  const answer = props?.params?.result?.answer
  let correctAnswer = props?.params?.result?.correct_answer
  if(!correctAnswer){
    correctAnswer = props?.correct_answer
  }
  const decidedRight = props?.params?.result?.decided_right

  const data = jsonConvert(props.answer_options, 'json')
 
  return <>
    <div className={s.wrapper}>
        <div className={s.cgecingOtv}>Варианты ответа:</div>
        <div className={s.multiChoiceTestContainer}>
          {data?.map((val, i) =>

           <div className={s.cgecingOtv}><span>{i + 1}.</span> <JsonPars json={val.text} /></div>)
            }

        </div>
      </div>
    <div className={s.answerWrap}>

    <span className={classNames(s.capsuleLeft, { [s.red]: decidedRight !== 1 })}>{userRole === 'children' ? 'Ваш ответ' : 'Ответ ребёнка'}</span>
    <div><span className={s.capsuleRight}>{answer ?? <span className='error'>нет ответа</span>}</span></div>
    {correctAnswer && <>
      <span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{correctAnswer}</span></div>
    </>}
  </div></>
}

const Task = ({ params, hist,  ...props }) => {
  const data = jsonConvert(props.answer_options, 'json')
  const { onAnswer, answer, status, hint, video, correctAnswer } = useTaskHomeWork(params,  props )

  return <>
 
    <div className={s.multiChoiceTestContainer}>
      {data?.map((val, i) =>
        <button key={i} onClick={() => { status < 5 && answer.set(val.index) }} className={classNames(s.btn, { [s.disable]: status > 4 }, { [s.active]: +answer.get === +val.index })}><JsonPars json={val.text} /></button>)}
    </div>

    {correctAnswer  && hist !== '?redo=ok' &&   params.type !== 'control'    && <div className={s.answerWrapTask}>
      <span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{correctAnswer}</span></div>
    </div>
    }

    <AnswerButton hintForChild={params.hintForChild} {...{ status, onAnswer, hint, video  }} />
  </>
}

const TaskDZ = ({ params, hist,  ...props }) => {
  const data = jsonConvert(props.answer_options, 'json')
  const { onAnswer, answer, status, hint, video, correctAnswer } = useTaskHomeWork(params,  props, undefined, [], true )
  const [arrayAnswer, setArrayAnswer] = useState(answer.get);

  return <>
 
      <div className={s.multiChoiceTestContainer}>
        {data?.map((val, i) =>
          <button key={i} onClick={() => { if(status >= 5) return;
            let arrr = Array.isArray(answer.get)? answer.get: [];
            if (arrr.includes(val.index)) {
                arrr = arrr.filter((el) => el != val.index );
            } else {
                  arrr = arrr.concat(val.index);
            }
            answer.set(arrr);
            setArrayAnswer(arrr);
            }} 
            className={classNames(s.btn, { [s.disable]: status > 4 },
                { [s.active]: (Array.isArray(arrayAnswer) && arrayAnswer.includes(val.index)) })}
          >
                <JsonPars json={val.text} /></button>)}
      </div>

    {correctAnswer  && hist !== '?redo=ok' &&   params.type !== 'control'    && <div className={s.answerWrapTask}>
      <span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{correctAnswer}</span></div>
    </div>
    }

    <AnswerButton hintForChild={params.hintForChild}  {...{ status, onAnswer, hint, video  }} />
  </>
}
