const initialState = {
	showMenuNewDesgn: true
};

const menuShowReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'MENUDESIGN':
			localStorage.setItem("showMenuNewDesgnAllPage", !state.showMenuNewDesgn);
			return {
				...state,
				showMenuNewDesgn: !state.showMenuNewDesgn,
			};
		case 'MENUDESIGNFIRST':
			localStorage.setItem("showMenuNewDesgnAllPage", action.data);
			return {
				...state,
				showMenuNewDesgn: action.data,
			};
		default:
			return state;
	}
};

export default menuShowReducer;

export const menuShowDesgn = () => {
	return { 
		type: 'MENUDESIGN'
	 }
};

export const menuShowDesgnFirst = (data) => {
	return { 
		type: 'MENUDESIGNFIRST', data
	 }
};