import * as C from './components';

export const listOptions = (type, flagEasily) => {
	if (flagEasily === null) {
		flagEasily = 1;
	}
	switch (type) {
		case 'exact-answer':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.CorrectAnswerMulti,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.CorrectAnswerMulti,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.Key,
				];
			}
		case 'order':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.Sequence,
					C.SequenceType,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.Sequence,
					C.SequenceType,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.Key,
				];
			}
		case 'multiple-choice':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.Sequence,
					C.OrderImportant,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.Sequence,
					C.OrderImportant,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.Key,
				];
			}
		case 'test':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.OptionsAnswer,
					C.CorrectAnswer,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.OptionsAnswer,
					C.CorrectAnswer,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.Key,
				];
			}
		case 'pass-words':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Omissions,
					C.Images,
					C.Audios,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Omissions,
					C.Images,
					C.Audios,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.Key,
				];
			}
		case 'match':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.MatchList,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.MatchList,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.Key,
				];
			}
		case 'probe-c':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Key,
					C.Weight,
				];
			} else {
				return [C.Question, C.Images, C.Key];
			}
		default:
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.NumberOfAttempts,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
				];
			} else {
				return [C.Question, C.Images, C.Audios, C.NumberOfAttempts, C.Hint, C.Video, C.Key];
			}
	}
};
