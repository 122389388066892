import classNames from 'classnames'
import Preloader from '../../../UI/Preloader'
import { Hint } from '../Hint/Hint'
import { VideoAnswer } from '../VideoAnswer/VideoAnswer'
import s from './AnswerButton.module.css'
import JsonPars from '../../../UI/JsonPars/JsonPars'

export const AnswerButton = ({ status, onAnswer, hint, video, isEditing, hintForChild }) => {
  return <div className={s.actionWrapper}>
    {hintForChild?.content && (status > 1) && <div className={s.comment}>Комментарий: <JsonPars json={hintForChild} /> </div>}

    <Button status={status} onAnswer={onAnswer} isEditing={isEditing} />
   
    {!hintForChild && hint && <Hint hint={hint} />}
    {video && <VideoAnswer video={video} />}
  </div>
}

const Button = ({ status, onAnswer: { handler, loading, isAnswer }, isEditing }) => {
  if (isEditing || status == 0) {
    return (
      <button
        type="submit"
        disabled={!isAnswer}
        className={classNames('btn')}
        onClick={handler}
      >
        {loading ? <Preloader /> : 'Ответить'}
      </button>
    );
  }

  switch (status) {
    case 2: return (<>
      <div className={s.clue}>Выберите верный ответ и затем отправьте его</div>
      <button type='submit' disabled={!isAnswer} className={classNames('btn btn--red')} onClick={handler}>{loading ? <Preloader /> : 'Ответить еще раз'}</button>
      </>)
    case 7: return <button type='button' className='btn btn--red' style={{ opacity: 1 }} disabled>Ответ принят</button>
    default: return <button type='button' className='btn btn--violet' style={{ opacity: 1 }} disabled>Ответ принят</button>
  }
}
