import { useEffect, useState } from 'react';
import { homeTaskApi } from '../api';
import { formateData } from '../components/common/Task/formateData';
import { errorListScroll } from '../helpers/errorListScroll';
import { jsonConvert } from '../helpers/jsonConvert';

const initNewTask = {
	number_exam_id: null,
	topic_id: null,
	subtopic_id: null,
	question: '',
	type: 'test',
	images: [],
	audio: [],
	answer_options: [],
	complexity: null,
	key_text: '',
	key_files: [],
	number_of_attempts: 2,
	prompt: '',
	video_solution: '',
	exam_weight: [{ score: 0, description: 'Нет ответа' }],
};

export const useTask = (data, save, saveLS, cancelLS, removeLS) => {
	const [loading, setLoading] = useState(false);
	const id = data?.id;

	const [state, setState] = useState({ ...initNewTask, ...data });
	const [errorsList, setErrorsList] = useState([]);
	const [showErrors, setShowErrors] = useState(false);

	const onSave = () => {
		setShowErrors(true);
		if (!errorsList.valid) return errorListScroll(errorsList);
		setLoading(true);

		const data = new FormData();
		data.append('topic_id', state['topic_id']);
		data.append('subtopic_id', state['subtopic_id']);
		data.append('type', state['type']);
		data.append('number_of_attempts', state['number_of_attempts']);
		data.append('question', jsonConvert(state['question']));
		data.append('answer_options', jsonConvert(state['answer_options'] ?? [{}]));

		if (state['complexity']) data.append("complexity", state['complexity'])
		if (state['video_solution'])
			data.append('video_solution', state['video_solution']);

		if (state['key_text'])
			data.append('key_text', jsonConvert(state['key_text']));
		if (state['prompt']) data.append('prompt', jsonConvert(state['prompt']));
		if (state['exam_weight'])
			data.append('exam_weight', jsonConvert(state['exam_weight']));

		if (typeof state['number_exam_id'] === 'number') data.append("number_exam_id", state['number_exam_id'])
		if (typeof state['direction_id'] === 'number')
			data.append('direction_id', state['direction_id']);
		if (typeof state['correct_answer'] === 'number')
			data.append('correct_answer', state['correct_answer']);
		else if (state['correct_answer'])
			data.append('correct_answer', jsonConvert(state['correct_answer']));

		if (state['option_one_mismatch_order'] !== undefined)
			data.append(
				'option_one_mismatch_order',
				state['option_one_mismatch_order']
			);
		if (state['option_all_mismatch_order'] !== undefined)
			data.append(
				'option_all_mismatch_order',
				state['option_all_mismatch_order']
			);
		if (state['option_order_matters'] !== undefined)
			data.append('option_order_matters', state['option_order_matters']);

		if (state['images']?.length > 0)
			state['images'].forEach((item) => data.append('images[]', item.file));
		if (state['audio']?.length > 0)
			state['audio'].forEach((item) => data.append('audio[]', item.file));
		if (state['key_files']?.length > 0)
			state['key_files'].forEach((item) =>
				data.append('key_files[]', item.file)
			);

		// добавление нового
		if (!state?.id)
			return homeTaskApi
				.add({ data, expand: ['homeTaskFiles'] })
				.then((res) => {
					setLoading(false);

					if (res?.data?.data?.id) {
						
						let modifiedRes = res?.data?.data;
						modifiedRes.devIdUnique = state?.devIdUnique;
						

						if (saveLS)
							saveLS({
								state: modifiedRes,
								devID: state.devID,
							});
						save();
					}
				});

		// обновление
		homeTaskApi
			.upd({ id: state.id, data, expand: ['homeTaskFiles'] })
			.then((res) => {
				setLoading(false);
				if (res.statusText === 'OK') {
					if (saveLS) saveLS({ state: res?.data?.data });
					else window.location.reload()
					save();
				}
			});
	};

	// удаление
	const onDel = () => {
		if (removeLS) return removeLS(data.id);
		homeTaskApi.remove(data.id).then((res) => {
			if (res.status === 204 || res.status === 200) {
				data.removeInListTask && data.removeInListTask(data.id)
			}
		});
	};

	const onCancel = () => {
		if (cancelLS) cancelLS(data.devID);
		save();
	};

	return [
		state,
		setState,
		onSave,
		onCancel,
		onDel,
		setErrorsList,
		showErrors,
		loading,
	];
};
