import s from './index.module.css';
import style from '../../index.module.css';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const NumberOfAttempts = ({ data, edit }) => {
  const handleChange = (e) => {
    const value = e.target.value;

    edit((prev) => ({
      ...prev,
      number_of_attempts: value === '' ? '' : Number(value),
    }));
  };

  const handleBlur = () => {
    edit((prev) => ({
      ...prev,
      number_of_attempts: prev.number_of_attempts === '' ? 2 : prev.number_of_attempts,
    }));
  };

  return (
    <div className={s.wrapper}>
      <div>
        <span className={style.heading}>Кол-во перерешиваний: </span>
        <Tooltip title="Если учащийся не верно сделает задание, то ему система напишет об этом, покажет подсказку и у него будет возможность сразу же перерешать это задание ещё раз. Данное поле контролирует то, сколько раз учащийся может перерешать конкретное задание">
          <InfoCircleOutlined className={s.infoTooltip} />
        </Tooltip>
      </div>
      <Input
        type="number"
        className={style.inputNew}
        value={data?.number_of_attempts ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};
