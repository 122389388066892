import React from 'react';
import {controlApi, homeWorkApi} from '@/api';
import {jsonConvert} from '@/helpers/jsonConvert';
import {useLocation} from 'react-router-dom';

export const useTaskHomeWork = (
    params,
    {children_id, homeWork_id, id, type = 'text'},
    noRightAnswer,
    files = [],
    multiple = false,
    setIsEditing = () => { }
) => {
    const [answer, setAnswer] = React.useState(
        type === 'arr' ? [] : ''
    );
    const [hint, setHint] = React.useState(params?.result?.prompt ?? '');
    const [video, setVideo] = React.useState(
        params?.result?.video_solution ?? ''
    );
    const [loading, setLoading] = React.useState(false);
    const [correctAnswer, setCorrectAnswer] = React.useState(
        jsonConvert(params?.result?.correct_answer ?? '', 'json') ?? ''
    );
    const loc = useLocation()
    const setRowValue = (value) =>
        params?.setStatRowValues?.((prev) => {
                return prev.map((item) => (item.id === id ? {...item, value} : item))
            }
        );

    const onAnswer = (e) => {
        setLoading(true);
        e.preventDefault();
        const data = new FormData();
        data.append('answer', (type === 'arr' || multiple) ? jsonConvert(answer) : answer);
        data.append('children_id', children_id);
        //data.append('answer', type === 'arr' ? jsonConvert(answer) : answer);
        data.append('answer_type', type === 'file' ? 'file' : 'text');
        data.append('task_id', id);

        files?.forEach((file => {
            data.append('answer_files[]', file);
        }))

        const api = () => (params.type === 'control' ? controlApi : homeWorkApi);

        api()
            .sendAnswer({id: homeWork_id, data})
            .then((res) => {
                setLoading(false);
                setIsEditing(false);
                if (res.data?.status) {
                    if (params.type === 'control') {
                        if (res.data.data.decided) {
                            if (noRightAnswer) params.status.set(5);
                            else params.status.set(6);
                            setRowValue(100);
                        } else {
                            params.status.set(7);
                            setRowValue(20);
                        }
                    } else {
                        if (res.data.data?.correct_answer)
                            setCorrectAnswer(
                                jsonConvert(res.data.data?.correct_answer, 'json')
                            );

                        if (res.data.data?.decided) setRowValue(100);
                        else setRowValue(20);

                        if (res.data.data.decided) {
                            if (noRightAnswer) params.status.set(5);
                            else params.status.set(6);
                        } else if (res.data.data.attempt === 1) {

                            params.status.set(7);
                        } else if (res.data.data.attempt > 1) {
                            params.status.set(2);
                        } else if (res.data.data.attempt === 1 && loc.search === '?redo=ok') {
                            params.status.set(7);
                        } else if (res.data.data.attempt < 1 && loc.search === '?redo=ok') {
                            params.status.set(2);
                        }
                        setHint(res.data.data?.prompt);
                        setVideo(res.data.data?.video_solution);
                    }
                } else console.error('status error');
            });
    };

    return {
        onAnswer: {
            handler: onAnswer,
            loading,
            isAnswer: !!answer,
        },
        answer: {get: answer, set: setAnswer},
        status: params?.status?.get,
        hint,
        video,
        correctAnswer,
    };
};
