import classNames from 'classnames'
import { jsonConvert } from '../../../../helpers/jsonConvert'
import { AnswerButton } from '../AnswerButton/AnswerButton'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import s from './Multi.module.css'


export const Multi = ({ type, ...props }) => {
  console.log(type)
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task hist={props?.hist}  {...props} />
  }
}

const Correction = () => {
  return <input className={classNames(s.input, s.inputCorrection)} placeholder='Напишите ответ' disabled={true} />
}

const Decided = ({ userRole, ...props }) => {

  const answer = props?.params?.result?.answer
  const correctAnswer = jsonConvert(props.params?.result?.correct_answer, 'json')
  const decidedRight = props?.params?.result?.decided_right
  return <div className={s.answerWrap}>
    <span className={classNames(s.capsuleLeft, { [s.red]: !decidedRight })}>{userRole === 'children' ? 'Ваш ответ' : 'Ответ ребёнка'}</span>
    <div><span className={s.capsuleRight}>{answer?.length > 0 ? answer : <span className='error'>Нет ответа</span>}</span></div>
    {correctAnswer && <><span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{(typeof correctAnswer === 'object') ? correctAnswer[0]?.text : correctAnswer}</span></div></>}
  </div>
}

const Task = ({ hist, params, ...props }) => {
  console.log(props)
  const { onAnswer, answer, status, hint, video, correctAnswer } = useTaskHomeWork(params,  props)

  return <form onSubmit={onAnswer.handler}>
    <input className={s.input} value={answer.get} onChange={e => answer.set(e.target.value)} placeholder='Напишите ответ' disabled={status > 4} />
    {correctAnswer && hist !== '?redo=ok' &&  params.type !== 'control' && <div className={s.answerWrapTask}>
      <span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{(typeof correctAnswer === 'object') ? correctAnswer[0]?.text : correctAnswer}</span></div>
    </div>}
    <AnswerButton hintForChild={params.hintForChild}  {...{ status, onAnswer, hint, video  }} />
  </form>
}
