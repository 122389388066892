import {useEffect, useState} from 'react'
import { directionAPI, controlApi, homeWorkApi, probeApi } from '@/api'
import { useInputNew } from '@UI/InputNew/useInputNew'
import { InputSelect } from '@UI/InputNew'
import Button from '@UI/Button'
import { rubberLayout } from '@/helpers/rubberLayout'
import {useAddAlert} from "@/hooks/useAddAlert";

const ChooseSeminarian = ({data, setActive}) => {
    const addAlert = useAddAlert()
    useEffect(rubberLayout, [])
    const [errorsList, setErrorsList] = useState([])
    const [showErrors, setShowErrors] = useState(false)

    const [teacher, setTeacher] = useState('')
    const [teachers, setTeachers] = useState([])

    useEffect(()=>{
        let arrOfTeacher=[]
        directionAPI.getTeacherByDiraction({ filter: [`[id][eq]=${data.direction.id}`]
        })?.then( res  => {
                res?.data?.data?.map(item => {
                    item?.directionSeminarians.forEach(item=> {
                        arrOfTeacher.push({ label: item.seminarian.name, value: item.seminarian.id })
                    })
                })
                setTeachers(arrOfTeacher);
            }
        )
        
    }, [data.direction.id])

    const [bindTeachers] = useInputNew({
        name: 'teacher',
        value: teacher,
        onChange: setTeacher,
        onError: setErrorsList,
        validate: showErrors,
        placeholder: 'Выберите педагога',
        title: 'Педагог',
        options: teachers,
        errorText: 'Не выбран педагог',
    })

    const modalSave = () => {
        const api = data.type == "control" ? controlApi.edit : data.type == "probe" ? probeApi.edit : (data.type == "group" || data.type == "private") ? homeWorkApi.edit : null;
        if(!api){
            addAlert({title: 'Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error'});
            return;
        }
        setShowErrors(true)
        if (errorsList.valid) {
            const dataSend = {
                id: data.id,
                data: {
                    "teacher_id": teacher,
                }
            }
            api(dataSend).then(res => {
                
                if (res?.data?.data?.id !== undefined){
                    addAlert({title: 'Успешно', text: 'Педагог выбран'})
                    data.setState((prevState) => {
                        return prevState.map((el)=>{
                            if(data.id==el.id){
                                return {
                                    ...el,
                                    teacher: res?.data?.data.teacher
                                }
                            }
                            return el;
                        })
                    })
                    setActive(false)
                }    
                else {
                    addAlert({title: 'Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error'})
                }
            })
        }
    }

    return <div style={{background: '#fff'}}>
        <h2 className="modal__title">Выберите педагога</h2>
        <InputSelect newDesign {...bindTeachers} />
        <Button 
            newDesign 
            newDesignYellow
            action={modalSave} 
            style={{marginTop:'20px'}}>Сохранить</Button>
    </div>;
}

export default ChooseSeminarian;