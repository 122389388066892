import s from "./Task.module.css";
import { useEffect, useState } from "react";
import { ChoosingOption } from "./ChoosingOption/ChoosingOption";
import { Multi } from "./Multi/Multi";
import { DetailedResponse } from "./DetailedResponse/DetailedResponse";
import { Images } from "./Images/Images";
import { Audios } from "./Audios/Audios";
import { InputFile } from "./InputFile/InputFile";
import { Missing } from "./Missing/Missing";
import { Match } from "./Match/Match";
import { InfoList } from "./InfoList/InfoList";
import { Comment } from "./Comment/Comment";
import classNames from "classnames";
import { ButtonDel, ButtonEdit } from "../../UI/Button";
import { useSelector } from "react-redux";
import { selectProfile } from "@store/profile.reducer";
import { CheckMenu } from "./CheckMenu/CheckMenu";
import CheckBox from "../../UI/CheckBox";
import JsonPars from "../../UI/JsonPars/JsonPars";
import { getInArr } from "../../../helpers/getInArr";
import { jsonConvert } from "../../../helpers/jsonConvert";
import { formateData } from "./formateData";
import { EditMode } from "./EditModeCard/EditMode";
import { GetIco } from "../../../utils/icons";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import {useParams} from "react-router-dom";

const Task = (props) => {
  const formattedData = formateData.getAll(props);

  switch (props.props?.category) {
    // состояние просмотра решенных
    case "decided":
      return <Decided {...formattedData} />;

    // состояния проверки
    case "check":
      return <Check {...formattedData} />;

    // состояния просмотра
    case "viewing":
      return <Viewing {...formattedData} />;

    // состояния просмотра и редактирования
    case "addendumEdit":
      return <AddendumEdit data={formattedData.props} {...formattedData} />;

    // состояния редактирования
    case "edit":
      return <Edit data={formattedData.props} {...formattedData} />;

    // состояния выбора
    case "select":
      return <Select data={formattedData.props} {...formattedData} />;

    // состояние решения
    default:
      return <Dz {...formattedData} />;
  }
};

export default Task;

const Dz = ({ props: task, title, type, ...props }) => {
  let isRedoHomeWork = props.redo_homework;
  let hist = props.hist;
  console.log(task.type)
  console.log(task)
  console.log(props)
  const hintForChild = props?.isDisplayTaskCommentOnSuccessAndFailure ? task.prompt : '';
  const isMobile = useScreenWidth(769);
  /*
    0 - нет ответа
    2 - второй шанс
    5 - принят
    6 - правильны
    7 - не правильны
  */

  const [status, setStatus] = useState(() => {
    if (type === 'control' && props?.result) {


      if (props?.result?.decided_right === 1 && props.status !== 5 ) return 6
      else return 7
    }
     if (hist === '?redo=ok') return 0;
    else if (
      props?.result?.decided_right === 1 &&
      props.status !== 5
    )
      return getInArr(task.type, ["detail-answer", "file-answer"]) ? 5 : 6;


    else if (props?.result?.attempt < 2) return 7;
    else if (props.status > 4) return 7;
    else if (props?.result?.attempt === 2) return 2;
    return 0;
  });

  const imgFile = Array.isArray(task?.homeTaskFiles) ? task.homeTaskFiles.filter((item) => item.type === "image") : [];
  const audioFile = Array.isArray(task?.homeTaskFiles) ? task?.homeTaskFiles?.filter(
    (item) => item.type === "audio"
  ) : [];

  task.homeWork_id = +props.homeWork_id;
  task.children_id = props.children_id;

  const params = {
    status: { get: status, set: setStatus },
    result: props.result,
    setStatRowValues: props.setStatRowValues,
    type,
    hintForChild
  };

  return <div className={s.card}>
    <div className={s.head}>
      <div>
        <div className={s.headHeader}>
          <div className={s.headTitle}>
            <h2 className={s.title}> Задание {title} {process.env.REACT_APP_DEV_MODE === '1' &&
              <span className='error'>{task.id}</span>}</h2>
            {(status === 7 || status === 2) &&
              <span className={classNames(s.status, s.incorrect)}>Решено неверно</span>}
            {status === 6 &&   <span className={classNames(s.status, s.correct)}>Решено верно</span>}
            {status === 5 &&   <span className={classNames(s.status, s.accept)}>Решение принято</span>}
          </div>

            {isMobile && (
              <InfoList
                ball={task?.ball}
                complexity={task?.complexity}
                number={task?.numberExam?.number}
                weight={task?.exam_weight}
              />
            )}
          </div>

          {imgFile?.length > 0 && <Images images={imgFile} />}

          {audioFile?.length > 0 && <Audios audios={audioFile} />}

          {task?.question && (
            <p className={s.question}>
              <JsonPars json={task.question} />
            </p>
          )}
        </div>

        {!isMobile && (
          <InfoList
            ball={task?.ball}
            complexity={task?.complexity}
            number={task?.numberExam?.number}
            weight={task?.exam_weight}
          />
        )}
      </div>

      {/* Варианты ответа */}
      {task.type === "test" && (
        <ChoosingOption {...{ ...task, hist, params }} />
      )}

    {/* Простой ввод */}
    {getInArr(task.type, ['exact-answer', 'order', 'multiple-choice']) && <Multi {...{...task, hist,   params}} />}

    {/* Большое поле ввода */}
    {task.type === 'detail-answer' && <DetailedResponse  {...{...task,hist,  params}} />}

    {/* Загрузки файла */}
    {task.type === 'file-answer' && <InputFile {...{...task, hist,  params}} />}

    {/* Пропущенные слова */}
    {task.type === 'pass-words' && <Missing {...{...task, hist,  params}} />}

    {/* Сопоставление слов */}
    {(task.type === 'match') && <Match {...{...task, hist,   params}} />}

  </div>
}

const Decided = ({ props: task, title, ...props }) => {
  const userRole = useSelector(selectProfile).role;
  const params = { result: props.result };

  return (
    <div className={s.card}>
      <div className={s.head}>
        <div>
          <Header {...{ ...props, ...task, title }} />

          <div className={s.answers}>
            {/* Варианты ответа */}
            {task.type === "test" && (
              <ChoosingOption
                {...{ ...task, params, type: "decided", userRole }}
              />
            )}
            {/* Простой ввод */}
            {getInArr(task.type, [
              "exact-answer",
              "order",
              "multiple-choice",
            ]) && <Multi {...{ ...task, params, type: "decided", userRole }} />}

            {/* Большое поле ввода */}
            {task.type === "detail-answer" && (
              <DetailedResponse
                {...{ ...task, params, type: "decided", userRole }}
              />
            )}

            {/* Загрузки файла */}
            {task.type === "file-answer" && (
              <InputFile {...{ ...task, params, type: "decided", userRole }} />
            )}

            {/* Пропущенные слова */}
            {task.type === "pass-words" && (
              <Missing {...{ ...task, params, type: "decided", userRole }} />
            )}
          </div>
        </div>

        <InfoList
          ball={props.result?.score}
          complexity={task?.complexity}
          number={task?.numberExam?.number}
          weight={task?.exam_weight}
        />
      </div>

      {/* Сопоставление слов */}
      {task.type === "match" && (
        <Match {...{ ...task, params, type: "decided", userRole }} />
      )}

      {(params?.result?.comment ||
        jsonConvert(params?.result?.comment_files, "json")?.length > 0) && (
        <Comment {...{ type: task.category, params }} />
      )}
    </div>
  );
};

const Check = ({ props: task, title, ...props }) => {
  const [fileList, setFileList] = useState({mass:[], add: false});
  const updateList = (mass) => {
    setFileList({mass, add: true})
  } 
  const params = { result: props.result };

  return (
    <div className={s.card}>
      <div className={s.head}>
        <div>
          <Header {...{ ...props, ...task, title }} />

          <div className={s.answers}>
            {/* Варианты ответа */}
            {task.type === "test" && (
              <ChoosingOption {...{ ...task, params, type: "decided" }} />
            )}
            {/* Простой ввод */}
            {getInArr(task.type, [
              "exact-answer",
              "order",
              "multiple-choice",
            ]) && <Multi {...{ ...task, params, type: "decided" }} />}

            {/* Большое поле ввода */}
            {task.type === "detail-answer" && (
              <DetailedResponse updateList={updateList} homeWork_id={props.homeWork_id} teacherAnswer={props.teacherAnswer} {...{ ...task, params, type: "decided" }} />
            )}

            {/* Загрузки файла */}
            {task.type === "file-answer" && (
              <InputFile updateList={updateList} homeWork_id={props.homeWork_id} teacherAnswer={props.teacherAnswer} {...{ ...task, params, type: "decided" }} />
            )}

            {/* Пропущенные слова */}
            {task.type === "pass-words" && (
              <Missing {...{ ...task, params, type: "decided" }} />
            )}
          </div>
        </div>

        <InfoList
          ball={props?.ball}
          complexity={props?.complexity}
          number={task?.numberExam?.number}
          weight={props?.weight}
        />
      </div>

      {/* Сопоставление слов */}
      {task.type === "match" && (
        <Match {...{ ...task, params, type: "decided" }} />
      )}

      <CheckMenu fileList={fileList} {...{ ...task, ...props }} />
    </div>
  );
};

const Viewing = ({ props: state, add, setAdd, ...props }) => {
  return (
    <div className={s.card}>
      <Correction {...{ ...state, ...props }} />
    </div>
  );
};

const AddendumEdit = ({ add, setAdd, props, setStatus, title, direction }) => {
  const [editMode, setEditMode] = useState(false);

  const save = () => {
    setStatus && setStatus(new Date());
    setEditMode(false);
  };

  return (
    <div className={s.card}>
      {editMode ? (
        <EditMode data={props} save={save} />
      ) : (
        <>
          <Correction {...props} title={title} direction={direction} />

          <div className={s.bottomMenu}>
            <ButtonEdit className={s.btnFn} onClick={() => setEditMode(true)} />
            {/* {!add && <ButtonAdd className={s.btnFn} onClick={() => setAdd(true)} />} */}
            {add && (
              <ButtonDel className={s.btnFn} onClick={() => setAdd(false)} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Edit = ({
  props,
  setStatus,
  saveLS,
  moveLS,
  removeLS,
  cancelLS,
  title,
  direction,
}) => {
  const [editMode, setEditMode] = useState(props.question ? false : true);

  const save = () => {
    setStatus && setStatus(new Date());
    setEditMode(false);
  };

  return (
    <div className={s.card}>
      {editMode || !props.question ? (
        <EditMode
          data={props}
          save={save}
          saveLS={saveLS}
          removeLS={removeLS}
          cancelLS={cancelLS}
          direction={direction}
        />
      ) : (
        <>
          <Correction {...props} title={title} />

          <div className={s.bottomMenu}>
            <ButtonEdit className={s.btnFn} onClick={() => setEditMode(true)} />

            {moveLS && (
              <>
                <ButtonMove type="up" onClick={() => moveLS.up(props.id)} />
                <ButtonMove type="dn" onClick={() => moveLS.down(props.id)} />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Select = ({ props, select, title, selectTask }) => {
  const [isSelect, setIsSelect] = useState(
    selectTask?.find((item) => item.id === props.id) ? true : false
  );

  const onSelect = () => {
    select(
      selectTask?.find((item) => item.id === props.id)
        ? selectTask.filter((item) => item.id !== props.id)
        : [...selectTask, props]
    );
    setIsSelect(!isSelect);
  };

  return (
    <div className={s.card}>
      <Correction {...props} title={title} />

      <div className={s.bottomMenu}>
        <CheckBox
          value={isSelect}
          hardValue={true}
          onClick={onSelect}
          className={s.checkbox}
        />
      </div>
    </div>
  );
};

const Correction = (props) => {
  return (
    <>
      <div className={s.head}>
        <div>
          <Header {...props} />

          <div className={s.addendum}>
            {/* Варианты ответа */}
            {props.type === "test" && props["answer_options"] && (
              <ChoosingOption
                {...{}}
                type="correction"
                variants={jsonConvert(props["answer_options"], "json")}
              />
            )}

            {/* Простой ввод */}
            {getInArr(props.type, [
              "exact-answer",
              "order",
              "multiple-choice",
            ]) && <Multi type="correction" />}

            {/* Большое поле ввода */}
            {props.type === "detail-answer" && (
              <DetailedResponse type="correction" />
            )}

            {/* Загрузки файла */}
            {props.type === "file-answer" && <InputFile type="correction" />}
          </div>
        </div>

        <InfoList
          ball={props?.ball}
          complexity={props?.complexity}
          number={props?.numberExam?.number}
          weight={props?.weight}
          staticData={props.staticData}
        />
      </div>

      {/* Пропущенные слова */}
      {props.type === "pass-words" && (
        <Missing type="correction" omissions={props["answer_options"]} />
      )}

      {/* Сопоставление слов */}
      {props.type === "match" && props["answer_options"]?.left && (
        <Match
          type="correction"
          variantsL={props["answer_options"].left}
          variantsR={props["answer_options"].right}
        />
      )}
    </>
  );
};

const Header = (props) => {
  const imgFile = props?.homeTaskFiles?.filter((item) => item.type === "image");
  const audioFile = props?.homeTaskFiles?.filter(
    (item) => item.type === "audio"
  );
  return (
    <>
      <div className={s.headTitle}>
        <h2 className={s.title}>
          Задача {props.title ? props.title : props.id}{" "}
          {process.env.REACT_APP_DEV_MODE === "1" && (
            <span className="error">{props.id}</span>
          )}
        </h2>
      </div>

      {imgFile?.length > 0 && <Images images={imgFile} />}

      {audioFile?.length > 0 && <Audios audios={audioFile} />}

      {props?.question && (
        <p className={s.question}>
          <JsonPars json={props.question} />
        </p>
      )}
    </>
  );
};

const ButtonMove = ({ type, ...props }) => {
  if (type === "up")
    return (
      <button {...props} className={s.buttonMove}>
        <GetIco icon="arrow_top" />
      </button>
    );
  return (
    <button {...props} className={s.buttonMove}>
      <GetIco icon="arrow_bottom" />
    </button>
  );
};
