import {supportAPI, timetableAPI, directionAPI} from '../../api';
import { TimetableService } from '../../services/TimetableService';
import store from "../../store/redux.store";

const SET_DIRECTION = 'impulse/common/SET_DIRECTION';
const SET_CLASSES = 'impulse/common/SET_CLASSES';
const SET_ALLDIRECTIONS = 'impulse/common/SET_ALLDIRECTIONS';
const SET_GROUPS = 'impulse/common/SET_GROUPS';
const SET_TIMETABLE = 'impulse/common/SET_TIMETABLE';
const SET_UNREAD_REQUESTS_COUNT = 'impulse/common/SET_UNREAD_REQUESTS_COUNT'
const CHANGE_TIMETABLE = 'impulse/common/CHANGE_TIMETABLE';
const INCREASE_REPORT_CHANGES_COUNT = 'impulse/common/INCREASE_REPORT_CHANGES_COUNT';

const convertToSelectAndIds = (arr, isClasses = false) => {
	const toSelect = [];
	const toIds = [];
	const keyName = isClasses ? 'title' : 'name'

	arr.forEach(({ [keyName]: label, id: value, direction_id }) => {
		toSelect.push({
			label,
			value,
			...(direction_id ? { direction_id } : {}),
		});
		toIds.push(value);
	});
	return { select: toSelect, ids: toIds };
};

const directionLS = localStorage.getItem('myDirections');
const groupsLS = localStorage.getItem('myGroups');

const directionJSON = directionLS ? JSON.parse(directionLS) : [];
const groupsJSON = groupsLS ? JSON.parse(groupsLS) : [];

const { select: selectDirection, ids: idsDirection } =
	convertToSelectAndIds(directionJSON);
const { select: selectGroup, ids: idsGroup } =
	convertToSelectAndIds(groupsJSON);

const initialState = {
	myDirections: directionJSON,
	myDirectionsIds: idsDirection,
	myDirectionsSelect: selectDirection,

	myClasses: [],
	myClassesIds: [],
	myClassesSelect: [],

	myGroups: groupsJSON,
	myGroupsIds: idsGroup,
	myGroupsSelect: selectGroup,

	myTimetable: {},
	reportChangesCount: 0,
	unreadRequestsCount: 0,
	allDirections: [],
	allDirectionsSelect: [],
};

const commonReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DIRECTION: {
			const { select, ids } = convertToSelectAndIds(action.state);
			localStorage.setItem('myDirections', JSON.stringify(action.state));
			return {
				...state,
				myDirections: action.state,
				myDirectionsIds: ids,
				myDirectionsSelect: select,
			};
		}
		case SET_CLASSES: {
			const { select, ids } = convertToSelectAndIds(action.state, true);
			// localStorage.setItem('myDirections', JSON.stringify(action.state));
			console.log(action?.state)

			return {
				...state,
				myClasses: action.state,
				myClassesIds: ids,
				myClassesSelect: select,
			};
		}
		case SET_ALLDIRECTIONS: {
			const { select } = convertToSelectAndIds(action.state);

			return {
				...state,
				allDirections: action.state,
				allDirectionsSelect: select,
			};
		}

		case SET_GROUPS: {
			const { select, ids } = convertToSelectAndIds(action.state);
			localStorage.setItem('myGroups', JSON.stringify(action.state));
			return {
				...state,
				myGroups: action.state,
				myGroupsIds: ids,
				myGroupsSelect: select,
			};
		}

		case SET_TIMETABLE:
			return {
				...state,
				myTimetable: action.state,
			};

		case SET_UNREAD_REQUESTS_COUNT:
			return {
				...state,
				unreadRequestsCount: action.state,
			};

		case CHANGE_TIMETABLE:
			return {
				...state,
				myTimetable: state.myTimetable?.map((item) =>
					item.id === action.item.id ? { ...item, ...action.item } : item
				),
			};
		case INCREASE_REPORT_CHANGES_COUNT:
			return {
				...state,
				reportChangesCount: +action.state > 0 ? state.reportChangesCount + +action.state : state.reportChangesCount + 1
			}
		default:
			return state;
	}
};

export default commonReducer;

// actions
export const setMyDirectionState = (state) => ({ type: SET_DIRECTION, state });

export const setAllDirectionsState = (state) => ({ type: SET_ALLDIRECTIONS, state });

export const loadAllDirection = () => (dispatch) => {
	directionAPI.getAll({})
		.then(({data})=>{
			dispatch(setAllDirectionsState(data.data))
		})
};

export const setMyClassesState = (state) => ({ type: SET_CLASSES, state });

export const setMyGroupsState = (state) => ({ type: SET_GROUPS, state });

export const setMyTimetableState = (state) => ({ type: SET_TIMETABLE, state });

export const setUnreadRequestsCount = (state) => ({ type: SET_UNREAD_REQUESTS_COUNT, state });

export const increaseReportChangesCount = (state) => ({ type: INCREASE_REPORT_CHANGES_COUNT, state });

export const getMyTimetableState = (date, month) => (dispatch) => {
	const apiCall = store.getState()?.profile?.profile?.role == 'children' ? timetableAPI.getChildren : timetableAPI.get;
	return apiCall({
		params: {
			expand:
				'direction',
			date: `${date.year}-${date.month}-01`,
		},
	})
	.then((res) => {
		console.log(TimetableService.getAllTimetable(res?.data?.data, month))
		dispatch(
			setMyTimetableState(
				TimetableService.getAllTimetable(res?.data?.data, month)
			)
		);
	});
}

export const loadUnreadRequestsCount = () => (dispatch) =>
	supportAPI
		.unreadCount()
		.then(({ data }) => dispatch(
			setUnreadRequestsCount(Number(data?.data?.count || 0)))
		);

export const changeMyTimetableState = (item) => ({
	type: CHANGE_TIMETABLE,
	item,
});

//selectors
export const selectorMyDirections = (state) => state.common.myDirections;
export const selectorMyDirectionsIds = (state) => state.common.myDirectionsIds;
export const selectorMyDirectionsSelect = (state) =>
	state.common.myDirectionsSelect;

export const selectorAllDirections = (state) => state.common.allDirections;
export const selectorAllDirectionsSelect = (state) => state.common.allDirectionsSelect;

export const selectorMyClasses = (state) => state.common.myClasses;
export const selectorMyClassesIds = (state) => state.common.myClassesIds;
export const selectorMyClassesSelect = (state) => state.common.myClassesSelect;

export const selectorMyGroups = (state) => state.common.myGroups;
export const selectorMyGroupsIds = (state) => state.common.myGroupsIds;
export const selectorMyGroupsSelect = (state) => state.common.myGroupsSelect;

export const selectorMyTimetable = (state) => state.common.myTimetable;
